
//[UPDATE]
export const windowGlobal = typeof window !== 'undefined' && window
export const exampleLogin : string  = 'nateneuhaus@protonmail.com'
export const examplePassword : string = 'passwordsover9000'
export const getRoot = ( port ?: number ) => {
    if( port ) return `http://localhost:${port}`
    else return `https://www.slapkard.com`
}


import React, { ReactNode } from 'react';
import { ENABLE_ERROR_LOGGING } from '../../settings/project-settings'

export interface Ref {
    [name: string]: any;
}

export type CSS = Record<string,any>

export const ifProp = (existsUseThis?: any, otherwiseUseThis?: any) => {
    if (existsUseThis) return existsUseThis;
    else return otherwiseUseThis;
};

export const ifPropBoolean = (exists?: boolean, existsUseThis?: any, otherwiseUseThis?: any) => {
    if (exists) return existsUseThis;
    else return otherwiseUseThis;
};

export const trimSafe = (  value : any ) => {
    if( value && value.length > 0 )
        return value.trim()
    return ""
}

export const isNotNull = ( obj : any ) => {
    return (obj !== undefined && JSON.stringify(obj) !== '{}')
}

export const onHover: string = ':hover';

export const getRandomString = (length?: number) => {
    length = length ? length : 10;
    var result = '';
    var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }
    return result;
};

export const capitalize = ( str : string ) => {
    if( !str || str.length <= 1 ) return str
    return (str.substring(0,1).toUpperCase()) + (str.substring(1, str.length))
}


export const getChildren = (children: any) => {
    const divs: Array<ReactNode> = [];
    React.Children.forEach(children, (child: any) => {
        divs.push(child);
    });

    return divs;
};

export const getChildrenLength = (children: any) => {
    const divs: Array<ReactNode> = [];
    let count : number = 0
    React.Children.forEach(children, (child: any) => {
        count++;
    });
    return count;
};

export const stringToFloat = (numberString: string) => {
    var s = numberString.replace(/[^\d.-]/g, '');
    return s;
};

export const stringContains = ( str : string, value : string ) => {
    if( str.indexOf(value) !== -1 ) return true
    return false
}

export const appendToObject = (obj: any, child?: Ref) => {
    if (child) return { ...obj, ...child };
    return obj;
};

export const setBackgroundGlobal = (color: string) => {};

export const getSquare = ( color?: string, x?: number | string ) => 
    <div style={{ 
        width : x ? x : 100, 
        height : x ? x : 100, 
        backgroundColor :  color ? color : 'red'
}}/>
    
export const getSquareWH = ( color?: string, w?: number | string, h?: number | string ) => 
    <div style={{ 
        width : w || w == 0 ? w : 100, 
        height : h|| w == 0 ? h : 100, 
        backgroundColor :  color ? color : 'red'
}}/>


export const indexOfNth = (str : string, key : string, nth : number ) =>  {
    var first_index = str.indexOf(key);
    var length_up_to_first_index = first_index + 1;

    if (nth == 1) {
        return first_index;
    } else {
        var string_after_first_occurrence = str.slice(length_up_to_first_index);
        var next_occurrence : number = indexOfNth(string_after_first_occurrence, key, nth - 1);

        if (next_occurrence === -1) {
            return -1;
        } else {
            return length_up_to_first_index + next_occurrence;  
        }
    }
}


export const isOnMainPage = () => {
    // * MUST BE RUN FROM WITHIN useEffect
    // * NOTE: this function will detect if user is on the main page dynamically based on the rule-of-three-slashes, 
    // such that no root url can contain more than three slashes, and no less than two slashes.
    
    const href = window.location.href
    const indexOfThirdSlash = indexOfNth(href, "/", 3)
    // 1. every url should have at least 3 occurances of the slash character if not on the root url
    // 2. if there is less than three, two occur in the http:// or https:// and the trailing slash has been removed
    // 3. if there is >= three slashes, the root url will have a string length of 0 after a substring is 
    //      taken after the third slash and then all slashes are removed
    const isMain = indexOfThirdSlash === -1 || trimSafe(href.substring(indexOfThirdSlash, href.length).replaceAll("/","")).length === 0
    return isMain
}
    
/***************************************************
 * name: injectProps
 * desc: adds props to every item in array of ReactNodes
 ***************************************************/
export const injectProps = (children: any[], props?: Record<string, any>) => {
    if (!props) return children;


    let updated: Array<ReactNode> = [], existingStyle : CSS
    {
        children.forEach((child: any) => {
            
            existingStyle = ifProp(child.props.style, {})

            props.style = {
                ...props.style,
                ...existingStyle
            }
            
        
            if (React.isValidElement(child)) {  
                updated.push(
                    React.cloneElement(child, {
                        //...existingStyle,
                        ...props
                    })
                );
            } else updated.push(child);
        });
    }
    return updated;
};

export const injectPropsII = (children: any[], props?: Record<string, any>) => {
    if (!props) return children;


    let updated: Array<ReactNode> = [], existingStyle : CSS
    {
        children.forEach((child: any, index : number ) => {
            
            existingStyle = ifProp(child.props.style, {})

            props.style = {
                ...props.style,
                ...existingStyle
            }
            if( ENABLE_ERROR_LOGGING ) console.log(index + ' : '+ JSON.stringify(existingStyle))
        
            if (React.isValidElement(child)) {  
                updated.push(
                    React.cloneElement(child, {
                        //...existingStyle,
                        ...props
                    })
                );
            } else updated.push(child);
        });
    }
    return updated;
};

export const getEmpty = (n: number) => {
    const nodes: ReactNode[] = [];
    while (n-- > 0) nodes.push(<></>);
    return nodes;
};

export const getEmptyObject = (n: number) => {
    let nodes: any = {}
    while (n-- > 0) 
    nodes = {
        ...nodes,
        [n] : {}
    }
    return nodes;
};

export const getColors = (index?: number) => {
    const colors: string[] = [
        'purple',
        'lime',
        'red',
        'blue',
        'orange',
        'yellow',
        'pink',
        'lightblue',
        'green'
    ];
    const color: string = index ? colors[index] : colors[0];
    if (index) return color;
    return colors;
};

export const getDisableSelectStyle = () => {
    const style: Record<string, any> = {
        '-webkit-touch-callout': 'none' /* iOS Safari */,
        '-webkit-user-select': 'none' /* Safari */,
        '-khtml-user-select': 'none' /* Konqueror HTML */,
        '-moz-user-select': 'none' /* Old versions of Firefox */,
        '-ms-user-select': 'none' /* Internet Explorer/Edge */,
        'user-select': 'none',
        '-webkit-tap-highlight-color':
            'transparent' /* removes mobile specific outline*/
    };
    return style;
};

export const getKeys = (obj: Ref) => {
    return Object.keys(obj);
};

export const __ : string = '\u00A0'

export const toParentScale = (parentPercent: number) => {
    /* 
    This can be used to 'escape' the percentage of the parent unit
    from scaling the values of a child percentage.
    
    For example, the above is useful when you want to have an element that
    is 500% of the parent div, then slide it back and forth on the x-axis
    to create a carousel effect. 
    
    If you want to have each carousel-item as 50% of its grand-parent div size,
    and the carousel itself as 250% of its parent div size, the orignal approach
    would look like the following: 

        parent: 250%
        child: 20%

    Using this approach, the inputs would instead look like the following: 

        parent: 250%
        child: 50% * unscale

    The reason for this is because in the normal case the percentage is relative. 
    In other words, when the parent element is 250% of its parent, the child element
    at 50% is really 50% of 250%, or 125%. To simplify things, this can be used. 

    */
    const unscale: number = 100 / parentPercent;
    return unscale;
};

export const stopBodyScroll = () => {
    // Use within componentDidMount()
    // or will fail on (back end) build.
    window.document.body.style.overflow = 'hidden';
};
export const resumeBodyScroll = () => {
    // Use within componentDidMount()
    // or will fail on (back end) build.
    window.document.body.style.overflow = 'visible';
    window.document.body.style.overflowX = 'hidden';
};

export const initBodyScroll = () => {
    // Use within componentDidMount()
    // or will fail on (back end) build.
    window.document.body.style.overflow = 'visible';
    window.document.body.style.overflowX = 'hidden';
    window.document.body.style.height = '100vh';
};



export const downloadFile = ( value : string, filename : string ) => {
    const element = document.createElement("a");
    const file = new Blob([value], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
}

export const getGoogleFormSubmission = (
    url : string,
    entries : Array<{ key : string, value : string }>
) => {
   let urlcompile : string = url + "?"
   entries.forEach(( item : any, index : number )=>{ 
        urlcompile += (index === 0 ? "" : "&") + item.key + '=' + encodeURIComponent(item.value)
   })
   // return urlcompile
   return urlcompile
}