import React, { ReactNode, useEffect, useRef, useState } from 'react'

import { Helmet } from 'react-helmet'
import { createGlobalStyle } from 'styled-components';
import SlapLogoWhiteBorderOpacity from '../images/slap-logo-white-bordered-opacity.svg'
import { getEmpty } from '../locust/composables/Shared';
import Crop from '../locust/composables/Crop';
import SpacerV from '../locust/sections/SpacerV';


const Row = ( props : { 
    cols : number, 
    y : number,  
    s?: number,
    x ?: number  
    c1 ?: string,
    c2 ?: string, 
    c3 ?: string, 
    c4 ?: string


}) => getEmpty(props.cols).map(( value : any, index : number )=><Triangle 
    s={props.s ? props.s : 1 } 
    x={index + (props.x ? props.x : 0)} 
    y={props.y}
    c1={props.c1}
    c2={props.c2}
    c3={props.c3}
    c4={props.c4}
    />
)


export default ( props : { off ?: boolean }) => {


    const mock : any[] = getEmpty(10)

    const colorsWhites : any = {
        c1 : 'rgba(255,255,255,0.1)',
        c2 : 'rgba(255,255,255,0.2)',
        c3 : 'rgba(255,255,255,0.3)',
        c4 : 'rgba(255,255,255,0.4)',
    }

    const opacity : number = 0.030625
    const colors : any = {
        c1 : `rgba(0,0,0,${opacity/2.5})`,
        c2 : `rgba(0,0,0,${opacity/2})`,
        c3 : `rgba(0,0,0,${opacity/1.5})`,
        c4 : `rgba(0,0,0,${opacity/1})`,
    }

    const yh : number = 0.75
    return (

        <>

       
        
            <div style={{
                // display : 'grid',
                // gridTemplateColumns: 'repeat(10, 200px)',
                // gridTemplateRows: 'repeat(6, 230px)',
                // transform: 'translate(-3%, -4%)',
                //backgroundColor : 'black',
                width : '100vw', 
                height : '100vh', 

                //backgroundImage : `url(${SlapLogoWhiteBorderOpacity})`,
                backgroundSize : '50px',
                backgroundRepeat : 'space'

            }}>

                { !props.off && 
                    <div style={{ position : 'absolute', width : '50vw', height : '100vh', overflow : 'hidden'}}>
                        <Row {...colors} s={0.5} y={0} cols={9} />
                        <Row {...colors} s={0.5} y={yh} cols={10} x={-0.5}/>
                        <Row {...colors} s={0.5} y={yh*2} cols={9} x={0}/>
                        <Row {...colors} s={0.5} y={yh*3} cols={10} x={-0.5}/>
                        <Row {...colors} s={0.5} y={yh*4} cols={9} x={0}/>
                        <Row {...colors} s={0.5} y={yh*5} cols={10} x={-0.5}/>
                        <Row {...colors} s={0.5} y={yh*6} cols={9} x={0}/>
                        <Row {...colors} s={0.5} y={yh*7} cols={10} x={-0.5}/>
                        <Row {...colors} s={0.5} y={yh*8} cols={9} x={0}/>
                        <Row {...colors} s={0.5} y={yh*9} cols={10} x={-0.5}/>
                        <Row {...colors} s={0.5} y={yh*10} cols={9} x={0}/>
                    </div>
                }

             
                

{/* 
                <Triangle2 x={0} y={0} s={2}/> 
                <Triangle2 x={1} y={0} s={2}/> 
                <Triangle2 x={2} y={0} s={2}/> 
                <Triangle2 x={3} y={0} s={2}/> 
                <Triangle2 x={4} y={0} s={2}/> 
                <Triangle2 x={5} y={0} s={2}/> 


                <Triangle2 x={0.5} y={0.75} s={2}/> 
                <Triangle2 x={1.5} y={0.75} s={2}/> 
                <Triangle2 x={2.5} y={0.75} s={2}/>  */}
                
                




          


                
               
                </div>
            


            

        
           
        
        
        </>
    )

}




const Triangle2 = ( props : { x : number, y : number, s ?: number }) => {
    const scale : number = props.s ? props.s : 1 
    const w : string = `${5 * scale }vw`;
    const h : string = `${5.25 * scale }vw`;
    return  <div style={{ position : 'absolute', marginLeft : `calc(${w} * ${props.x})`, marginTop : `calc(${h} * ${props.y})` }}><svg style={{
        width: w,
        height: h,
        //transform : `translate(${props.x * w },${props.y * h})`,
        WebkitClipPath: 'polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%)',
        clipPath: 'polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%)'
    }} viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
        
        <polygon points="" fill="none" stroke="#1877F2" stroke-width="5">
        <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
        <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
        <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
        <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    </svg>
    </div>
}


const Triangle = ( props : { x : number, y : number, s : number, c1 ?: string, c2 ?: string, c3 ?: string, c4 ?: string   }) => {
    const w : number = 200;
    const h : number = 230;
    const c1 : string = props.c1 ? props.c1 : '#1877F2'
    const c2 : string = props.c2 ? props.c2 : 'hsl(240,100%,70%)'
    const c3 : string = props.c3 ? props.c3 : 'hsl(160,100%,70%)'
    const c4 : string = props.c4 ? props.c4 : 'hsl(80,100%,70%)'
    
    return  <div style={{ position : 'absolute', marginLeft : w  * props.x * props.s, marginTop : h  * props.y * props.s}}><svg style={{
        width: w * props.s,
        height: h * props.s,
        //transform : `translate(${props.x * w },${props.y * h})`,
        WebkitClipPath: 'polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%)',
        clipPath: 'polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%)'
    }} viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
        
        <polygon points="" fill="none" stroke={c1} stroke-width="5">
        <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke={c2} stroke-width="5">
        <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke={c3} stroke-width="5">
        <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    <polygon points="" fill="none" stroke={c4} stroke-width="5">
        <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
    </polygon>
    </svg>
    </div>
}


const PolygonTriangle = ( props : { index : number }) => {


    //const rowSize : number = 6 
    let transform = ""
    let skip = false

    if( props.index > 9){
        transform = 'translate(-50%, -25%)'
    }
    
    if( props.index > 19){
        transform = 'translate(0%, -50%)'
    }

    if( props.index > 29){
        transform = 'translate(-50%, -75%)' // + 50
    }

    if( props.index > 39){
        transform = 'translate(0%, -100%)'
    }

    if( props.index > 49) transform = 'translate(-50%, -125%)' // + 50 

    if( props.index > 59) transform = 'translate(0%, -150%)'

    if( props.index > 69) transform = 'translate(-50%, -175%)' // + 50 

    if( props.index > 79) transform = 'translate(0%, -200%)'

    if( props.index > 89) transform = 'translate(-50%, -225%)' // + 50 

    if( props.index > 99) transform = 'translate(0%, -250%)'
    
    //if( props.index > 35) transform = 'translate(0%, -50%)'
    
    
    // if( props.index === 21  ) transform = 'translate(0%, -50%)'


    return (
        <svg style={{
            width: 200,
            height: 230,
            WebkitClipPath: 'polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%)',
            clipPath: 'polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%)'
        }} viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
            
            <polygon points="" fill="none" stroke="#1877F2" stroke-width="5">
            <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
        </polygon>
        <polygon points="" fill="none" stroke="hsl(240,100%,70%)" stroke-width="5">
            <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
        </polygon>
        <polygon points="" fill="none" stroke="hsl(160,100%,70%)" stroke-width="5">
            <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
        </polygon>
        <polygon points="" fill="none" stroke="hsl(80,100%,70%)" stroke-width="5">
            <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
        </polygon>
        </svg>
    )
}